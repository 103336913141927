import React, { useEffect, useState, useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Link,
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  IconButton,
  TableContainer,
  TablePagination
} from '@material-ui/core';
import NotificationsActiveOutlinedIcon from '@material-ui/icons/NotificationsActiveOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { I18nContext } from 'translations';
import { comodatoStatusClass } from 'utils';
import EmptyData from '../EmptyData';
import styles from '../style';

const CommodateTable = ({
  data,
  classes,
  getCommodate,
  editComodato,
  notifyCommodate,
  recordsPerPage,
  cancelCommodate,
  receiveCommodate
}) => {
  const [currentPage, setCurrentPage] = useState(data.page);
  const { translate } = useContext(I18nContext);

  useEffect(() => {
    setCurrentPage(data.page);
  }, [data]);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
    getCommodate(newPage);
  };

  if (!data || (data?.data.length <= 0)) {
    return <EmptyData message={translate('thereIsNoData')} />;
  }

  return (
    <>
      <TableContainer>
        <Table className={classes.tableStyle} size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>{translate('commodate.table.id')}</TableCell>
              <TableCell>{translate('commodate.table.creationDate')}</TableCell>
              <TableCell>{translate('commodate.table.driver')}</TableCell>
              <TableCell>{translate('commodate.table.items')}</TableCell>
              <TableCell>{translate('commodate.table.returnDate')}</TableCell>
              <TableCell>{translate('commodate.table.state')}</TableCell>
              <TableCell style={{ width: '200px' }}>{translate('commodate.table.actions')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(data?.data || []).map(commodate => (
              <TableRow
                hover
                key={commodate?._id}
              >
                <TableCell>
                  {commodate?._id}
                </TableCell>
                <TableCell>
                  {commodate?.created_at}
                </TableCell>
                <TableCell>
                  <Link
                    color="primary"
                    rel="noopener noreferrer"
                  >
                    {commodate?.borrower?.name || ' - '}
                  </Link>
                </TableCell>
                <TableCell>
                  {commodate?.commodates_amount || ' - '}
                </TableCell>
                <TableCell>
                  {commodate?.return_date || ' - '}
                </TableCell>
                <TableCell>
                  <span className={comodatoStatusClass(commodate?.status?.code)}>
                    {commodate?.status?.name || ' - '}
                  </span>
                </TableCell>
                <TableCell>
                  <IconButton onClick={() => editComodato(commodate?._id, false)}>
                    <VisibilityOutlinedIcon fontSize="small" />
                  </IconButton>
                  <>
                    {((commodate.status?.code === 2) || (commodate.status?.code === 3) || (commodate.status?.code === 5)) &&
                      <IconButton onClick={() => notifyCommodate(commodate?._id)}>
                        <NotificationsActiveOutlinedIcon fontSize="small" />
                      </IconButton>
                    }
                    {commodate.status?.code === 1 &&
                      <IconButton onClick={() => editComodato(commodate?._id, true)}>
                        <EditOutlinedIcon fontSize="small" />
                      </IconButton>
                    }
                    {((commodate.status?.code === 4) || (commodate.status?.code === 5)) &&
                      <Button
                        onClick={() => receiveCommodate(commodate?._id)}
                        variant="outlined"
                        style={{ marginRight: 10 }}
                      >
                        {translate('commodate.table.actions.receive')}
                      </Button>
                    }
                    {commodate.status?.code === 1 &&
                      <IconButton onClick={() => cancelCommodate(commodate?._id)}>
                        <ClearOutlinedIcon fontSize="small" />
                      </IconButton>
                    }
                  </>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={data.total ? data.total : data.pages * recordsPerPage}
        page={currentPage - 1}
        rowsPerPageOptions={[]}
        rowsPerPage={recordsPerPage}
        onChangePage={(event, newPage) => handleChangePage(event, newPage + 1)}
      />
    </>
  );
};

function areEqual(prevProps, nextProps) {
  return prevProps.data === nextProps.data;
}

export default withStyles(styles)(React.memo(CommodateTable, areEqual));
