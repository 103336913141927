import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { Button, Menu } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { I18nContext } from 'translations';
import styles from './style';
import Language from 'components/Language';
import { RENT } from 'utils/constants';

const ItemsMenu = ({ classes, history }) => {
  const isRent = process.env.REACT_APP_API_URL_BASE_IS_RENT === RENT;
  const { translate, langCode } = useContext(I18nContext);
  const [pathname, setPathname] = useState(history.location);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    setPathname(history.location);
  }, [history.location]);

  const handleClick = event => {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  }

  const handleClose = () => {
    setAnchorEl(null);
  }

  const shopifyData = history.location.search.split('?')[1];

  return (
    <div className={classes.itemsMenu}>
      <div className={classes.menu}>
        {!isRent && 
          <>
            <Button to="/" color="primary" component={Link} className={pathname.pathname === "/" && pathname.hash === "" ? "active" : ""}>
              {translate('landing.menu.start')}
            </Button>
            <Button to="/tracking-code" color="primary" component={Link} className={pathname.pathname === "/tracking-code" && pathname.hash === "" ? "active" : ""}>
              {translate('landing.menu.tracking')}
            </Button>
            <Button
              color="primary"
              aria-owns={anchorEl ? "simple-menu" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
              onMouseOver={handleClick}
              className={(pathname.pathname === "/mercadoflex" || pathname.pathname === "/shopify") && pathname.hash === "" ? "active" : ""}
            >
              {translate('landing.menu.integrations')}
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              MenuListProps={{ onMouseLeave: handleClose }}
              style={{ top: 37 }}
            >
              {(langCode === 'es' || langCode === 'pt') && (
                <Button to="/mercadoflex" component={Link} fullWidth style={{ justifyContent: 'left' }}>
                  {translate('landing.menu.mercadoflex')}
                </Button>
              )}
              <Button to="/shopify" component={Link} fullWidth style={{ justifyContent: 'left' }}>{translate('landing.menu.shopify')}</Button>
              <Button to="/woocommerce" component={Link} fullWidth style={{ justifyContent: 'left' }}>{translate('landing.menu.woocommerce')}</Button>
            </Menu>
            {(pathname.pathname === '/' || pathname.pathname === '/mercadoflex' || pathname.pathname === '/shopify') && (
              <Button href="#contact" color="primary" className={pathname.hash === '#contact' ? 'active' : ''}>
                {translate('landing.menu.contact')}
              </Button>
            )}
            <Button href="https://www.enviosylogistica.com/" color="primary" target="_blank">
              {translate('landing.menu.blog')}
            </Button>
          </>
        }
      </div>
      <div className={classes.menuRight}>
        <Button href={shopifyData ? `/login?${shopifyData}`: "/login"} color="primary" className={pathname.pathname === "/login" && pathname.hash === "" ? "active" : ""}>
          {translate('landing.menu.logIn')}
        </Button>
        <Button href={isRent ? "/signup" : "/presignup"} variant="contained" color="primary" className="btn-rounded">
          {translate('landing.menu.signUp')}
        </Button>
        <Language colorIcon="#7825bd" />
      </div>
    </div>
  )
}

ItemsMenu.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(withRouter(ItemsMenu));