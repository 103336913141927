import React, { lazy, Suspense } from 'react';
import { LinearProgress } from '@material-ui/core';
import withHelmet from 'utils/withHelmet';
const LayoutLanding = lazy(() => import('components/Landing/Layouts'));
const Banner = lazy(() => import('components/Landing/Banners/Banner'));
const Express = lazy(() => import('components/Landing/Express'));
const Cargo = lazy(() => import('components/Landing/Cargo'));
const Benefits = lazy(() => import('components/Landing/Benefits'));
const PiboxEnterprise = lazy(() => import('components/Landing/PiboxEnterprise'));
const Contact = lazy(() => import('components/Landing/Layouts/Contact'));

const Landing = () => (
  <Suspense fallback={<LinearProgress color="primary" />}>
    <LayoutLanding>
      <Banner />
      <Express />
      <Cargo />
      <Benefits />
      <PiboxEnterprise />
      <Contact />
    </LayoutLanding>
  </Suspense>
)

export default withHelmet({
  title: 'Envíos y Mensajería Urbana | Pibox',
  description: 'Realiza envíos de paquetes y carga a todo el país, con nuestra mensajería urbana y desde nuestra plataforma tecnológica fácil de usar, Ingresa ahora y conócela.',
  linkCanonical: 'https://pibox.app',
  image: 'https://pibox.app/images/landing/cargo.png'
})(Landing);