class Sale {
  constructor({
    date_of_purchase,
    destination,
    fulfillment_status,
    order_id,
    origin,
    passenger_id,
    product_name,
    product_price,
    shipper,
    store_id,
    store_name,
    updated_at,
    booking_id,
    relaunched,
    status_cd,
    order,
  }) {
    this.date_of_purchase = date_of_purchase;
    this.destination = destination;
    this.external_id = order.external_id;
    this.fulfillment_status = fulfillment_status;
    this.order_id = order_id;
    this.origin = origin;
    this.passenger_id = passenger_id;
    this.product_name = product_name;
    this.product_price = product_price;
    this.shipper = shipper;
    this.store_id = store_id;
    this.store_name = store_name;
    this.updated_at = updated_at;
    this.booking_id = booking_id;
    this.relaunched = relaunched;
    this.status_cd = status_cd;
    this.order = order;
  }
}

export default Sale
