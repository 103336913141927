import React, { useState, useContext, useEffect, useCallback } from 'react';
import { Snackbar, Button, Slide } from '@material-ui/core';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import { I18nContext } from 'translations';
import { useStateValue } from 'context/store';
import {
  cleanSales,
  downloadExcelSales,
  createPibox,
  listSalesShopify,
  listAllSales,
} from 'context/sales/actions';
import { message as closeMessage } from 'context/commons/actions';
import { INITIAL_PAGE, PER_PAGE } from 'utils/constants';
import TitleWithDowload from 'components/Titles/TitleWithDowload';
import SalesTable from 'components/Tables/Sales';
import SalesTableFilters from 'components/Tables/Sales/Filters';
import Loading from 'components/Loading';
import Sidebar from 'components/Sidebar';
import BackdropSync from 'components/Modals/BackdropSync';
import Form from './Form';

const Sales = ({ history }) => {
  const { translate } = useContext(I18nContext);
  const [
    {
      sales: { loading, list, saleList },
      commons: { message },
    },
    dispatch,
  ] = useStateValue();
  const [per_page] = useState(PER_PAGE);
  const [isSync, setIsSync] = useState(false);
  const [filters, setFilters] = useState(null);
  const [open, setOpen] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [selected, setSelected] = useState([]);
  const [updateAddress, setUpdateAddress] = useState(null);
  const [haveData, setHaveData] = useState(false);

  const getSales = useCallback(
    async (page, isUpdating = null) => {
      // filters?.store_name ? listSalesShopify(dispatch, { page, per_page, ...filters }) : listSales(dispatch, { page, per_page, ...filters })
      listAllSales(dispatch, {
        page,
        per_page,
        update: isUpdating,
        ...filters,
      });
    },
    [dispatch, per_page, filters]
  );

  useEffect(() => {
    getSales(INITIAL_PAGE);
    return () => closeMessage(dispatch, null);
  }, [getSales, dispatch]);

  useEffect(() => {
    const interval = setInterval(() => {
      // list && list.synchronizing && getSales(INITIAL_PAGE);
    }, 7500);
    return () => clearInterval(interval);
  }, [getSales, dispatch, list]);

  useEffect(() => {
    setHaveData(true);
    return () => cleanSales(dispatch);
  }, [dispatch]);

  useEffect(() => {
    saleList && history.push('/app/bookings/new');
  }, [saleList, history, dispatch]);

  const onFilters = (params) => setFilters(params);

  const handleChangeCheck = (checks, item) => {
    setShowButton(Object.values(checks).some((j, k) => j === true));

    if (item.checked) {
      setSelected((prev) => prev.concat(item));
    } else {
      setSelected((prev) => prev.filter((r) => r._id !== item._id));
    }
  };

  const handleAddress = (sale) => {
    setUpdateAddress(sale);
    setOpen(true);
  };

  const goToCreatePibox = () => {
    createPibox(dispatch, selected, true);
  };

  const sendSyncSales = async () => {
    setIsSync(true);
    getSales(INITIAL_PAGE, true);
  };

  const downloadExcel = async () =>
    await downloadExcelSales(dispatch, { ...filters });

  const syncShopify = async () => {
    const filtersShopify = {
      ...filters,
      update: true,
    };
    listSalesShopify(dispatch, {
      page: INITIAL_PAGE,
      per_page,
      ...filtersShopify,
    });
  };

  const onClose = async () => {
    setIsSync(false);
    getSales(INITIAL_PAGE);
  }


  return (
    <div className="padding-page-full-height">
      {isSync &&
        <BackdropSync
          text="app.sync.text"
          onClose={onClose}
        />
      }
      <TitleWithDowload
        title={translate('app.sales')}
        icon={
          <TrendingUpIcon className="align-center" style={{ marginRight: 7 }} />
        }
        downloadExcel={downloadExcel}
        buttonSync={translate('app.sales.synchronizeSales')}
        sync={sendSyncSales}
        syncShopify={syncShopify}
        showOptionsShopify={filters?.store_name && true}
      />
      <div className="marginFiltersTable" style={{ paddingBottom: 10 }}>
        <SalesTableFilters
          onFilters={onFilters}
          list={list?.data}
          haveData={haveData}
          setHaveData={setHaveData}
          getSales={getSales}
        />
      </div>

      {list && (
        <SalesTable
          data={list}
          handleChangeCheck={handleChangeCheck}
          getSales={getSales}
          recordsPerPage={per_page}
          handleAddress={handleAddress}
        />
      )}

      {loading && <Loading />}

      <Slide direction="up" in={showButton} mountOnEnter unmountOnExit>
        {filters?.store_name ? (
          <Button
            onClick={goToCreatePibox}
            color="primary"
            variant="contained"
            className="fixed-bottom"
            size="small"
            disabled={
              filters?.store_name && selected?.length === 1 ? false : true
            }
          >
            {translate('app.createService')}
          </Button>
        ) : (
          <Button
            onClick={goToCreatePibox}
            color="primary"
            variant="contained"
            className="fixed-bottom"
            size="small"
          >
            {translate('app.createService')}
          </Button>
        )}
      </Slide>

      <Sidebar side="right" open={open} setOpen={setOpen} width="300px">
        <Form sale={updateAddress} getSales={getSales} setOpen={setOpen} />
      </Sidebar>

      <Snackbar
        style={{ zIndex: 99999 }}
        open={!!message}
        autoHideDuration={6000}
        onClose={() => closeMessage(dispatch, null)}
        message={translate(message)}
      />
    </div>
  );
};

export default Sales;
