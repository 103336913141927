import React, { useState, useContext, useCallback, useEffect } from 'react';
import MapIcon from '@material-ui/icons/Map';
import { Snackbar } from '@material-ui/core';
import { uploadFile } from 'utils/aws';
import { withRouter } from 'react-router-dom';
import { uploadOptimizeRoute } from 'context/operations/optimizeRoutes/actions';
import { message as closeMessage } from 'context/commons/actions';
import { useStateValue } from 'context/store';
import { I18nContext } from 'translations';
import Loading from 'components/Loading';
import { modalMessageHtml } from 'utils';
import { getCostCenter } from 'context/express/actions';
import FormOptimizeRoutes from './Form';
import Title from 'components/Titles';
import ModalFailedStops from 'components/Modals/ModalFailedStops';

function OptimizeRoutes({ history }) {
  const { translate } = useContext(I18nContext);
  const [{ commons: { message }, optimizeRoutes: { failedRoutes }, checkin: { checkin } }, dispatch] = useStateValue();
  const [fileUpload, setFileUpload] = useState([]);
  const [city, setCity] = useState(null);
  const [loading, setLoading] = useState(false);
  const [origin, setOrigin] = useState();
  const [costCenter, setCostCenter] = useState([]);
  const [openFailedStops, setOpenFailedStops] = useState(false);

  const handleFile = async file => setFileUpload(file);
  const handleSuggest = (info) => setOrigin(info);

  const handleModalFailedStops = () => {
    setOpenFailedStops(!openFailedStops);
  }

  const handleMessageOk = (statusInvalidTasks) => {
    modalMessageHtml(
    translate('congratulations'),
    '',
    'success',
    translate('continue')).then((response) => {
      if (response.isConfirmed) {
        history.push('/app/operations/routes-file-list');
      }
    });
  }

  const submit = async values => {
    //modalMessageHtml(translate('app.operations.optimize.messageFormatTitle'), `${translate('app.operations.optimize.messageFormatText1')} <br /><br /><a href="https://s3.amazonaws.com/static.pibox.app/templates/template_routes-sep.xlsx">${translate('app.operations.optimize.messageFormatButton')}</a><br /><br />${translate('app.operations.optimize.messageFormatText2')}`, 'warning', translate('continue'), true, translate('cancel'), true)
    //.then(async (status) => {
      //if (status.isConfirmed) {
        setLoading(true);
        const date = Date.parse(new Date());
        const response = await uploadFile(fileUpload[0], `booking_bulk_load/${date}`);
        const data = {
          file_url: response.Location,
          city_id: city._id,
          address: origin.name,
          lat: origin.lat,
          lon: origin.lon,
          ...values
        }
        if (response) {
          const callback = await uploadOptimizeRoute(dispatch, data);
          setLoading(false);
          callback && callback.status && handleMessageOk();
          callback && callback.statusInvalidTasks && handleModalFailedStops();
        }
      //}
    //});
  }

  const listCostCenter = useCallback(async () => {
    const callback = checkin?.company && await getCostCenter(checkin?.company._id);
    callback && callback.costCenter && setCostCenter(callback.costCenter);
  }, [checkin.company])

  useEffect(() => {
    listCostCenter();
  }, [listCostCenter])


  return (
    <div className="padding-page-full-height">
      <Title
        title={translate('app.operations.optimize.title')}
        icon={<MapIcon className="align-center" style={{ marginRight: 5 }} />}
      />
      {loading && <Loading />}
      <FormOptimizeRoutes
        handleFile={handleFile}
        handleSuggest={handleSuggest}
        submit={submit}
        origin={origin ? origin : false}
        file={fileUpload}
        city={city}
        setCity={setCity}
        dispatch={dispatch}
        setOrigin={setOrigin}
        costCenter={costCenter}
      />
      <ModalFailedStops
        handleModalFailedStops={handleModalFailedStops}
        open={openFailedStops}
        failedStops={failedRoutes}
        isOperations={true}
      />
      <Snackbar
        style={{ zIndex: 99999 }}
        open={!!message}
        autoHideDuration={6000}
        onClose={() => closeMessage(dispatch, null)}
        message={translate(message)}
      />
    </div>
  )
}

export default withRouter(OptimizeRoutes);