import {
  LIST_ROUTE_FILE,
  LIST_STOP_POINT,
  GO_TO_CREATE_PIBOX,
  LIST_OPTIMIZED_ROUTES,
  OPTIMIZE_ROUTES_FAILED,
  LOADING_LIST_ROUTE_FILE,
  LIST_ROUTE_NOT_OPTIMIZED,
  LOADING_LIST_OPTIMIZED_ROUTES
} from './constants';
import { flatFormat } from 'utils';

const optimizeRoutesReducer = (state, action) => {
  switch (action.type) {
    case OPTIMIZE_ROUTES_FAILED:
      return {
        ...state,
        failedRoutes: action.failedRoutes && action.failedRoutes.map(stop => {
          const info = {
            ...stop,
            packages: stop.packages ? [{
              ...stop.packages[0],
              declared_value: stop.packages[0].declared_value ? flatFormat({ sub_units: stop.packages[0].declared_value.sub_units, iso: stop.packages[0].declared_value.iso }) : null,
              collected_value: stop.packages[0].collected_value ? flatFormat({ sub_units: stop.packages[0].collected_value.sub_units, iso: stop.packages[0].collected_value.iso }) : null
            }] : []
          }
          return { ...info }
        }), 
      }
    case LIST_OPTIMIZED_ROUTES:
      const info = action.listOptimizedRoutes.page > 1
        ? { ...state.listOptimizedRoutes, data: state.listOptimizedRoutes.data.concat(action.listOptimizedRoutes.data)}
        : action.listOptimizedRoutes
      return {
        ...state,
        listOptimizedRoutes: info
      }
    case LOADING_LIST_ROUTE_FILE:
      return {
        ...state,
        loadingListRouteFile: action.loadingListRouteFile
      }
    case LIST_ROUTE_FILE:
      return {
        ...state,
        listRouteFile: action.listRouteFile
      }
    case LIST_ROUTE_NOT_OPTIMIZED:
      return {
        ...state,
        routeNotOptimized: action.routeNotOptimized
      }
    case LOADING_LIST_OPTIMIZED_ROUTES:
      return {
        ...state,
        loadingListOptimizedRoutes: action.loadingListOptimizedRoutes
      }
    case LIST_STOP_POINT:
      return {
        ...state,
        listStopPoint: action.listStopPoint
      }
    case GO_TO_CREATE_PIBOX:
      const route = action.routeOptimizeOps && action.routeOptimizeOps[0].stops.map(rt => {
        return {
          ...rt,
          packages: [{
              ...rt.packages[0],
              declared_value: rt.packages[0].declared_value ? flatFormat({ sub_units: rt.packages[0].declared_value.sub_units, iso: rt.packages[0].declared_value.iso }) : 0,
              collected_value: rt.packages[0].collected_value ? flatFormat({ sub_units: rt.packages[0].collected_value.sub_units, iso: rt.packages[0].collected_value.iso }) : 0
            }]
        }
      })
      return {
        ...state,
        routeOptimizeOps: route,
        originOptimizeOps: {
          name: action.originOptimizeOps ? action.originOptimizeOps.address : '',
          lat: action.originOptimizeOps ? action.originOptimizeOps.address_lat : '',
          lon: action.originOptimizeOps ? action.originOptimizeOps.address_lon : ''
        }
      }
    default:
      return state
  }
}

export default optimizeRoutesReducer;
