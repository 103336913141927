import React, { useEffect, useContext, useCallback, useState } from 'react';
import { Snackbar } from '@material-ui/core';
import ListAltIcon from '@material-ui/icons/ListAlt';
import { useStateValue } from 'context/store';
import Loading from 'components/Loading';
import BookingsTable from 'components/Tables/Bookings';
import BookingTableFilters from 'components/Tables/Bookings/Filters/BookingTableFilters';
import BookingTableGroupsFilters from 'components/Tables/Bookings/Filters/BookingTableGroupsFilters';
import TitleWithDowload from 'components/Titles/TitleWithDowload';
import { message as closeMessage } from 'context/commons/actions';
import {
  listBookings,
  cleanBookings,
  downloadExcelBooking,
  exportBookings,
} from 'context/express/actions';
import { I18nContext } from 'translations';
import { INITIAL_PAGE } from 'utils/constants';
import useGetDataExpress from 'hooks/Express/useGetDataExpress';

const TYPE = 0;
const ADMIN_COMPANY = 'admin';

const Bookings = ({ isRent }) => {
  const { translate } = useContext(I18nContext);
  const [openUsers, setOpenUsers] = useState(false);
  const [passenger, setPassenger] = useState(null);
  const [loadingExport, setLoadingExport] = useState(false);
  const [
    {
      bookings: { list, loadingBookings },
      checkin: { company },
    },
  ] = useStateValue();
  const {
    message,
    roles,
    per_page,
    user_id,
    setUser_id,
    filters,
    onFilters,
    dispatch,
    getListGroups,
    myServices,
    listByGroups,
    inputSearchSelected,
    setInputSearchSelected,
  } = useGetDataExpress(isRent);

  const getBookings = useCallback(
    async (page) => {
      const filterArea =
        inputSearchSelected === ADMIN_COMPANY
          ? { admin: true }
          : { area_id: inputSearchSelected };
      const passengerId = { passenger_id: passenger?.userId };
      listBookings(dispatch, {
        page,
        per_page,
        ...filters,
        user_id,
        ...filterArea,
        ...passengerId,
      });
    },
    [filters, dispatch, per_page, user_id, inputSearchSelected, passenger]
  );

  useEffect(() => {
    !inputSearchSelected && getBookings(INITIAL_PAGE);
  }, [filters, getBookings, inputSearchSelected]);

  useEffect(() => {
    return () => cleanBookings(dispatch);
  }, [dispatch]);

  const downloadExcel = async () => {
    const filterArea =
      inputSearchSelected === ADMIN_COMPANY
        ? { admin: true }
        : { area_id: inputSearchSelected };
    const passengerId = { passenger_id: passenger?.userId };
    await downloadExcelBooking(
      dispatch,
      { ...filters, user_id, ...filterArea, ...passengerId },
      TYPE
    );
  };

  useEffect(() => {
    inputSearchSelected && !user_id && getBookings(INITIAL_PAGE);
  }, [getBookings, inputSearchSelected, user_id]);

  const selectUser = (user) => {
    setPassenger({ userId: user._id, username: user.name });
    setOpenUsers(false);
  };

  const exportFileOk = () => {
    setLoadingExport(false);
    window.open('/app/reports/bookings', '_blank');
  };

  const exportFile = async () => {
    setLoadingExport(true);
    const filterArea =
      inputSearchSelected === ADMIN_COMPANY
        ? { admin: true }
        : { area_id: inputSearchSelected };
    const passengerId = { passenger_id: passenger?.userId };
    const callback = await exportBookings(
      dispatch,
      { ...filters, user_id, ...filterArea, ...passengerId },
      TYPE
    );
    callback.status ? exportFileOk() : setLoadingExport(false);
  };

  return (
    <div className="padding-page-full-height">
      {loadingBookings && <Loading />}

      <TitleWithDowload
        title={translate('app.services')}
        button={translate('app.bookings.download')}
        icon={
          <ListAltIcon className="align-center" style={{ marginRight: 5 }} />
        }
        downloadExcel={downloadExcel}
        buttonExport={translate('app.bookings.export')}
        exportFile={exportFile}
        loadingExport={loadingExport}
      />

      <div className="marginFiltersTable">
        <BookingTableFilters
          onFilters={onFilters}
          setPassenger={setPassenger}
          isRent={isRent}
        />
        {roles().length > 0 && (
          <BookingTableGroupsFilters
            user_id={user_id}
            setUser_id={setUser_id}
            myServices={myServices}
            listByGroups={listByGroups && listByGroups}
            getListGroups={getListGroups}
            setInputSearchSelected={setInputSearchSelected}
            selectUser={selectUser}
            openUsers={openUsers}
            setOpenUsers={setOpenUsers}
            passenger={passenger?.username}
            filters={filters}
            onFilters={onFilters}
            isRent={isRent}
          />
        )}
      </div>

      {list && (
        <BookingsTable
          data={list}
          getBookings={getBookings}
          recordsPerPage={per_page}
          user_id={user_id}
          company={company && company}
          isRent={isRent}
        />
      )}

      <Snackbar
        style={{ zIndex: 99999 }}
        open={!!message}
        autoHideDuration={6000}
        onClose={() => closeMessage(dispatch, null)}
        message={translate(message)}
      />
    </div>
  );
};

export default Bookings;
