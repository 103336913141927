import React, { lazy, Suspense} from 'react';
import { LinearProgress } from '@material-ui/core';
import withHelmet from 'utils/withHelmet';
const LayoutLanding = lazy(() => import('components/Landing/Layouts'));
const BannerShopify = lazy(() => import('components/Landing/Banners/BannerShopify'));
const UseShopify = lazy(() => import('components/Landing/Shopify/UseShopify'));
const AdvantagesShopify = lazy(() => import('components/Landing/Shopify/AdvantagesShopify'));
const LinkAccountsShopify = lazy(() => import('components/Landing/Shopify/LinkAccountsShopify'))
const Contact = lazy(() => import('components/Landing/Layouts/Contact'));

const LandingShopify = () => (
  <Suspense fallback={<LinearProgress color="primary" />}>
    <LayoutLanding>
      <BannerShopify />
      <UseShopify />
      <AdvantagesShopify />
      <LinkAccountsShopify />
      <Contact />
    </LayoutLanding>
  </Suspense>
)

export default withHelmet({
  title: 'Shopify E-commerce | Pibox',
  description: 'La alianza de Shopify con Pibox te permitirá llegar a todos tus clientes y sus productos mejorando tus tiempos de entrega',
  linkCanonical: 'https://pibox.app/shopify'
})(LandingShopify)
