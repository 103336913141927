import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Typography, Container, Grid, Button, Divider } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import AndroidIcon from '@material-ui/icons/Android';
import AppleIcon from '@material-ui/icons/Apple';
import { I18nContext } from 'translations';

const styles = createStyles((theme) => ({
  footer: {
    padding: '30px 20px 25px',
    backgroundColor: '#f5f5f5'
  },
  marginX: {
    margin: '0 22px',
    [theme.breakpoints.down('sm')]: {
      margin: 15
    }
  }
}));

const Footer = ({ classes }) => {
  const { translate, langCode } = useContext(I18nContext);
  return (
    <footer className={classes.footer}>
      <Container maxWidth="md" className="container-width-lg">
        <Grid container spacing={1} className="text-center-sm">
          <Grid item md={4} xs={12}>
            <Button size="small" href="https://www.facebook.com/picapco/" target="_blank" rel="nofollow">
              <FacebookIcon />
            </Button>
            <Button size="small" href="https://www.instagram.com/picap_col" target="_blank" rel="nofollow">
              <InstagramIcon />
            </Button>
            <Button size="small" href="https://twitter.com/Picapco" target="_blank" rel="nofollow">
              <TwitterIcon />
            </Button>
          </Grid>
          <Grid item md={8} xs={12} className="text-right text-center-sm">
            <Typography variant="subtitle1" color="textPrimary" component="span" className="block-sm">
              {translate('landing.footer.downloadOurApp')}
            </Typography>
            <Button href="https://play.google.com/store/apps/details?id=co.pibox&hl=es_CO" target="_blank" variant="outlined" className={`${classes.marginX} btn-rounded`} size="small" startIcon={<AndroidIcon />}>
              {translate('landing.footer.playStore')}
            </Button>
            <Button href="https://apps.apple.com/us/app/pibox/id1532537133" target="_blank" variant="outlined" className="btn-rounded" size="small" startIcon={<AppleIcon />}>
              {translate('landing.footer.appStore')}
            </Button>
          </Grid>
          <Grid item xs={12} className="text-center">
            <br />
            <Divider />
            <br />
            {langCode === 'es_mx' ?
              <Button to="/terms/mx/general-terms.pdf" target="_blank" component={Link} fullWidth size="small">
                {translate('generalTermsMx')}
              </Button>
            :
              <>
                <Button to="/terms/general-terms.pdf" target="_blank" component={Link} fullWidth size="small">
                  {translate('generalTerms')}
                </Button>
                <Button to="/terms/particular-terms-express.pdf" target="_blank" component={Link} fullWidth size="small">
                  {translate('particularTerms')}
                </Button>
                <Button to="/terms/particular-terms-domiciliary-services.pdf" target="_blank" component={Link} fullWidth size="small">
                  {translate('particularTermsDomiciliaryServices')}
                </Button>
                <Button to="/terms/terms-and-conditions-of-corporate-use-pibox.pdf" target="_blank" component={Link} fullWidth size="small">
                  {translate('termsCorporateUse')}
                </Button>
              </>
            }
            <br />
            {/*<Button to="/privacy" component={Link} size="small">
              {translate('landing.footer.privacy')}
            </Button>
            <span> - </span>*/}
            <Button to="/terms/personal-data-treatment-policy-pibox.pdf" target="_blank" component={Link} size="small">
              {translate('landing.footer.dataProcessingPolicy')}
            </Button>
            <span> - </span>
            <Button to="/pqrs" component={Link} size="small">
              PQRS
            </Button>
            <br />
            <Typography variant="body2" color="textPrimary" style={{ marginTop: 10 }}>
              {translate('landing.footer.copyright')}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </footer>
  )
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(Footer);