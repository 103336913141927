import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { NavLink, Link } from 'react-router-dom';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import MotorcycleIcon from '@material-ui/icons/Motorcycle';
import HomeIcon from '@material-ui/icons/Home';
import InputIcon from '@material-ui/icons/Input';
import StoreIcon from '@material-ui/icons/Store';
import MoneyIcon from '@material-ui/icons/Money';
import ReceiptIcon from '@material-ui/icons/Receipt';
import AppsIcon from '@material-ui/icons/Apps';
import SettingsIcon from '@material-ui/icons/Settings';
import AddIcon from '@material-ui/icons/Add';
import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
import LibraryBooksOutlinedIcon from '@material-ui/icons/LibraryBooksOutlined';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import ListAltIcon from '@material-ui/icons/ListAlt';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import ArchiveOutlinedIcon from '@material-ui/icons/ArchiveOutlined';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import MapIcon from '@material-ui/icons/Map';
import DirectionsIcon from '@material-ui/icons/Directions';
import TimelineIcon from '@material-ui/icons/Timeline';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import GradeOutlinedIcon from '@material-ui/icons/GradeOutlined';
import ThumbsUpDownOutlinedIcon from '@material-ui/icons/ThumbsUpDownOutlined';
import { I18nContext } from 'translations';
import styles from './LateralMenuStyle';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import { COUNTRY_LANGUAJE, ADDONS } from 'utils/constants';
import { piboxAdminRolesCompany, isAddonEnabled } from 'utils';

const LateralMenu = ({ open, classes, company, hasShopify, isRent, profile }) => {
  const { translate } = useContext(I18nContext);

  const roles = () => {
    let rol = [];
    if (profile && profile.roles) {
      rol = piboxAdminRolesCompany(profile.roles);
    }
    return rol;
  };

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <div className={classes.contentLogo}>
        {isRent ?
          <Link to="/app">
            <img src="/images/logoRent.svg" alt="Rent" />
          </Link>
        :
          <Link to="/">
            <img src="/images/logo.svg" alt="Pibox" />
          </Link>
        }
      </div>

      <List>
        <ListItem
          button
          key={1}
          exact
          to="/app"
          component={NavLink}
          activeClassName={classes.active}
        >
          <ListItemIcon className={classes.iconPrimary}>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary={translate('app.menu.start')} />
        </ListItem>

        <ListItem button key={2} className={classes.listWrap} component="li">
          <ListItem
            activeClassName={classes.active}
            to="/app/bookings"
            component={NavLink}
          >
            <ListItemIcon className={classes.iconPrimary}>
              {isRent ? <MotorcycleIcon /> : <InputIcon />}
            </ListItemIcon>
            <ListItemText primary={isRent ? translate('app.menu.rent') : translate('app.menu.express')} />
            <ArrowForwardIosIcon style={{ fontSize: 10 }} />
          </ListItem>

          <List component="ul" disablePadding className={classes.submenu}>
            <ListItem button component={NavLink} to="/app/bookings/new">
              <ListItemIcon className={classes.iconPrimary}>
                <AddIcon />
              </ListItemIcon>
              <ListItemText primary={translate('app.menu.express.create')} />
            </ListItem>
            <ListItem button component={NavLink} to="/app/bookings">
              <ListItemIcon className={classes.iconPrimary}>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary={translate('app.menu.express.services')} />
            </ListItem>
            {!isRent && (
              <>
                <ListItem button component={NavLink} to="/app/bookings/packages">
                  <ListItemIcon className={classes.iconPrimary}>
                    <ArchiveOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary={translate('app.menu.express.packages')} />
                </ListItem>
                <ListItem button component="a" href="/app/bookings/sales">
                  <ListItemIcon className={classes.iconPrimary}>
                    <TrendingUpIcon />
                  </ListItemIcon>
                  <ListItemText primary={translate('app.menu.express.sales')} />
                </ListItem>
                {company &&
                  <ListItem button component="a" href="/app/reserved">
                    <ListItemIcon className={classes.iconPrimary}>
                      <LibraryBooksOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary={translate('scheduledReservations')} />
                  </ListItem>
                }
              </>
            )}
          </List>
        </ListItem>

        {isRent && roles().length > 0 &&
          <ListItem button key={3} className={classes.listWrap} component="li">
            <ListItem
              activeClassName={classes.active}
              to="/app/promo-codes"
              component={NavLink}
            >
              <ListItemIcon className={classes.iconPrimary}>
                <MoneyIcon />
              </ListItemIcon>
              <ListItemText primary={translate('app.menu.codes')} />
              <ArrowForwardIosIcon style={{ fontSize: 10 }} />
            </ListItem>

            <List component="ul" disablePadding className={classes.submenu}>
              <ListItem button component={NavLink} to="/app/promo-codes/new">
                <ListItemIcon className={classes.iconPrimary}>
                  <AddIcon />
                </ListItemIcon>
                <ListItemText primary={translate('app.menu.generateCodes')} />
              </ListItem>
              <ListItem button component={NavLink} to="/app/promo-codes">
                <ListItemIcon className={classes.iconPrimary}>
                  <ListAltIcon />
                </ListItemIcon>
                <ListItemText primary={translate('app.menu.tripCodes')} />
              </ListItem>
            </List>
          </ListItem>
        }
          <ListItem button key={4} className={classes.listWrap} component="li">
            <ListItem
              activeClassName={classes.active}
              to="/app/payments/picash"
              component={NavLink}
            >
              <ListItemIcon className={classes.iconPrimary}>
                <AccountBalanceWalletOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={translate('app.menu.paymentMethods')} />
              <ArrowForwardIosIcon style={{ fontSize: 10 }} />
            </ListItem>

            <List component="div" disablePadding className={classes.submenu}>
              <ListItem button component={NavLink} to="/app/payments/picash">
                <ListItemIcon className={classes.iconPrimary}>
                  <AttachMoneyOutlinedIcon />
                </ListItemIcon>
                <ListItemText
                  primary={translate('app.menu.paymentMethods.picash')}
                />
              </ListItem>
              <ListItem
                button
                component={NavLink}
                to="/app/payments/bank-accounts"
              >
                <ListItemIcon className={classes.iconPrimary}>
                  <AccountBalanceIcon />
                </ListItemIcon>
                <ListItemText
                  primary={translate('app.menu.paymentMethods.bankAccounts')}
                />
              </ListItem>
              <ListItem
                button
                component={NavLink}
                to="/app/payments/credit-cards"
              >
                <ListItemIcon className={classes.iconPrimary}>
                  <CreditCardIcon />
                </ListItemIcon>
                <ListItemText
                  primary={translate('app.menu.paymentMethods.creditCards')}
                />
              </ListItem>
              {((roles().length > 0 || profile?.roles.filter((rol) => rol === 'pibox_financial')?.length > 0) && (company && COUNTRY_LANGUAJE[company.geo_fence_id] === 'es')) &&
                <ListItem
                  button
                  component={NavLink}
                  to="/app/payments/pipay"
                >
                  <ListItemIcon className={classes.iconPrimary}>
                    <ReceiptIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Pipay"
                  />
                </ListItem>
              }
            </List>
          </ListItem>
        {!isRent && (
          <ListItem button key={5} className={classes.listWrap} component="li">
            <ListItem
              activeClassName={classes.active}
              to="/app/integrations"
              component={NavLink}
            >
              <ListItemIcon className={classes.iconPrimary}>
                <AppsIcon />
              </ListItemIcon>
              <ListItemText primary={translate('app.menu.integrations')} />
              <ArrowForwardIosIcon style={{ fontSize: 10 }} />
            </ListItem>

            <List component="div" disablePadding className={classes.submenu}>
              <ListItem button component={NavLink} to="/app/bookings/sales">
                <ListItemIcon className={classes.iconPrimary}>
                  <TrendingUpIcon />
                </ListItemIcon>
                <ListItemText primary={translate('app.menu.express.sales')} />
              </ListItem>
              <ListItem button component={NavLink} to="/app/integrations">
                <ListItemIcon className={classes.iconPrimary}>
                  <AppsIcon />
                </ListItemIcon>
                <ListItemText primary={translate('app.menu.integrations')} />
              </ListItem>
            </List>
          </ListItem>
        )}

        {company && !isRent && (
          <>
            <ListItem button key={6} className={classes.listWrap} component="li">
              <ListItem
                activeClassName={classes.active}
                to="/app/operations/optimize-routes"
                component={NavLink}
              >
                <ListItemIcon className={classes.iconPrimary}>
                  <MapIcon />
                </ListItemIcon>
                <ListItemText primary={translate('app.menu.routes')} />
                <ArrowForwardIosIcon style={{ fontSize: 10 }} />
              </ListItem>

              <List component="div" disablePadding className={classes.submenu}>
                <ListItem
                  button
                  component={NavLink}
                  to="/app/operations/optimize-routes"
                >
                  <ListItemIcon className={classes.iconPrimary}>
                    <DirectionsIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={translate('app.menu.routes.optimizeRoutes')}
                  />
                </ListItem>
                <ListItem
                  button
                  component={NavLink}
                  to="/app/operations/routes-file-list"
                >
                  <ListItemIcon className={classes.iconPrimary}>
                    <TimelineIcon />
                  </ListItemIcon>
                  <ListItemText primary={translate('app.menu.routes.history')} />
                </ListItem>
              </List>
            </ListItem>

            <ListItem button key={7} className={classes.listWrap} component="li">
              <ListItem
                activeClassName={classes.active}
                to="/app/packages-grouping"
                component={NavLink}
              >
                <ListItemIcon className={classes.iconPrimary}>
                  <InputIcon />
                </ListItemIcon>
                <ListItemText primary={translate('prepackages')} />
                <ArrowForwardIosIcon style={{ fontSize: 10 }} />
              </ListItem>

              <List component="ul" disablePadding className={classes.submenu}>
                <ListItem button component={NavLink} to="/app/packages-grouping">
                  <ListItemIcon className={classes.iconPrimary}>
                    <AddIcon />
                  </ListItemIcon>
                  <ListItemText primary={translate('prepackages.newPrepackage')} />
                </ListItem>
                <ListItem button component={NavLink} to="/app/list-prepackages">
                  <ListItemIcon className={classes.iconPrimary}>
                    <ListAltIcon />
                  </ListItemIcon>
                  <ListItemText primary={translate('prepackages')} />
                </ListItem>
              </List>
            </ListItem>
          </>
        )}
        {company && !isRent &&
          <ListItem
            button
            key={8}
            to="/app/analytics"
            component={NavLink}
            activeClassName={classes.active}
          >
            <ListItemIcon className={classes.iconPrimary}>
              <AssessmentOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={translate('analytics.title')} />
          </ListItem>
        }
        {(company && !isRent && isAddonEnabled(company.enabled_addons, ADDONS[4])) &&
          <ListItem
            button
            key={9}
            to="/app/commodate"
            component={NavLink}
            activeClassName={classes.active}
          >
            <ListItemIcon className={classes.iconPrimary}>
              <ThumbsUpDownOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={translate('commodate.title')} />
          </ListItem>
        }

        <ListItem
          button
          key={10}
          to="/app/rate-bookings"
          component={NavLink}
          activeClassName={classes.active}
        >
          <ListItemIcon className={classes.iconPrimary}>
            <GradeOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary={translate('app.menu.rateServices')} />
        </ListItem>

        <ListItem
          button
          key={11}
          to="/app/settings"
          component={NavLink}
          activeClassName={classes.active}
        >
          <ListItemIcon className={classes.iconPrimary}>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary={translate('app.menu.settings')} />
        </ListItem>
        {!isRent &&
          <ListItem
            button
            key={12}
            exact
            to="/#contact"
            component={NavLink}
            activeClassName={classes.active}
          >
            <ListItemIcon className={classes.iconPrimary}>
              <HelpOutlineIcon />
            </ListItemIcon>
            <ListItemText primary={translate('app.menu.help')} />
          </ListItem>
        }
      </List>
      {company && (
        <List className={classes.company}>
          <ListItem component="li">
            <ListItemIcon className={classes.iconPrimary}>
              <StoreIcon />
            </ListItemIcon>
            <ListItemText primary={company.name} />
          </ListItem>
        </List>
      )}
    </Drawer>
  );
};

LateralMenu.propTypes = {
  open: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LateralMenu);
