import SwalAlert from 'sweetalert2';
import Money from './money';
import {
  DELIVERY_SALE_STATUS,
  DELIVERY_SALE_SHOPIFY_STATUS_FILTERS,
  DELIVERY_SALE_WOOCOMMERCE_STATUS,
  SALES_VTEX_STATUS,
} from 'utils/constants';
import { urlReportsBooking, urlReportPackages, urlCompany } from 'api';
import { format } from 'date-fns';

export const modalMessage = (
  title,
  text,
  type,
  nameButton = 'Ok',
  showIcon,
  isCancelButton = false,
  nameButtonCancel,
  isConfimButton = true,
  timer
) => {
  return SwalAlert.fire({
    title: title,
    text: text,
    showCancelButton: isCancelButton,
    cancelButtonText: nameButtonCancel,
    confirmButtonColor: '#7825bd',
    cancelButtonColor: '#a5a3a3',
    showConfirmButton: isConfimButton,
    confirmButtonText: nameButton,
    allowEscapeKey: false,
    allowOutsideClick: false,
    icon: showIcon && type,
    timer,
  });
};

// Message Modal HTML
export const modalMessageHtml = (
  title,
  text,
  type,
  nameButton = 'Ok',
  isCancelButton = false,
  nameButtonCancel,
  showIcon = true,
  width
) => {
  return SwalAlert.fire({
    title: title,
    html: text,
    showCancelButton: isCancelButton,
    cancelButtonText: nameButtonCancel,
    confirmButtonColor: '#6f2f82',
    cancelButtonColor: '#424242',
    confirmButtonText: nameButton,
    allowEscapeKey: false,
    allowOutsideClick: false,
    icon: showIcon && type,
    width: width ? width : 600,
    customClass: {
      content: 'swal-content-scroll',
    },
  });
};

// Class color for status_cd
export const statusClass = (status) => {
  let returnedClass = 'status-tag status-normal';
  if (status === 3) {
    returnedClass = 'status-tag status-expired';
  } else if (status === 4 || status === 5) {
    returnedClass = 'status-tag status-failed';
  } else if (status === 2) {
    returnedClass = 'status-tag status-ok';
  }
  return returnedClass;
};

export const optimizationStopsFileStatusesClass = (status) => {
  switch (status) {
    case 1:
      return 'status-ok-text';
    case 2:
      return 'status-failed-text';
    case 3:
      return 'status-cancelled-text';
    default:
      return 'status-progress-text';
  }
};

export const formatCostTemporal = (cost) => {
  if (!cost) {
    return '';
  }
  let subunits = null;
  if (cost?.subunits || cost?.subunits >= 0) {
    subunits = cost.subunits;
  }
  const { sub_units, iso } = cost;
  const formatted_cost = new Money({ subunits: sub_units || subunits, iso });
  return formatted_cost.format();
};

export const formatCost = (cost) => {
  if (!cost) {
    return '';
  }
  let subunits = null;
  if (cost?.subunits || cost?.subunits >= 0) {
    subunits = cost.subunits;
  }
  const { sub_units, iso } = cost;
  const formatted_cost = new Money({ subunits: sub_units || subunits, iso });
  return formatted_cost.format();
};

export const formatPrice = (cost) => {
  if (!cost) return '';
  const { sub_units, iso } = cost;
  let subunits = null;
  if (cost?.subunits || cost?.subunits >= 0) {
    subunits = cost.subunits;
  }
  const formatted_cost = new Money({ subunits: sub_units || subunits, iso });
  return formatted_cost.formatPrice();
};

export const flatFormat = (cost) => {
  if (!cost) return '';
  let subunits = null;
  if (cost?.subunits || cost?.subunits >= 0) {
    subunits = cost.subunits;
  }
  const { sub_units, iso } = cost;
  const formatted_cost = new Money({ subunits: sub_units || subunits, iso });
  return formatted_cost.flatFormat();
};

export const formatCostCurrency = (cost) => {
  if (!cost) {
    return '';
  }
  let subunits = null;
  let iso = null;
  if (cost?.subunits || cost?.subunits >= 0) {
    subunits = cost.subunits;
  }
  if (Boolean(cost.iso)) {
    iso = cost.iso;
  }
  const { sub_units, currency } = cost;
  const formatted_cost = new Money({
    subunits: sub_units || subunits,
    iso: currency || iso,
  });
  return formatted_cost.format();
};

export const booleanToString = (boolean) => {
  return boolean ? 'yes' : 'no';
};

export const convertToKm = (route) => `${(route / 1000).toFixed(3)} Km`;

export const convertToMin = (time) => `${(time / 60).toFixed()} min`;

export const bookingStatuses = (status) => {
  let returnedClass = 'status-tag status-normal';
  if (status === 101) {
    returnedClass = 'status-tag status-expired';
  } else if ([100, 102, 103, 104, 105, 300].includes(status)) {
    returnedClass = 'status-tag status-failed';
  } else if (status === 4 || status === 107) {
    returnedClass = 'status-tag status-ok';
  }
  return returnedClass;
};

// Class color for status_cd in detail
export const bookingStatusTextClass = (status) => {
  let returnedClass = 'status-progress-text';
  if (status === 101) {
    returnedClass = 'status-expired-text';
  } else if ([100, 102, 103, 104, 105, 300].includes(status)) {
    returnedClass = 'status-failed-text';
  } else if (status === 4 || status === 107) {
    returnedClass = 'status-ok-text';
  }
  return returnedClass;
};

// Class color for circle status_cd in detail
export const bookingStatusCircleClass = (status) => {
  let returnedClass = 'circle-shadow circle-status-progress';
  if (status === 101) {
    returnedClass = 'circle-shadow circle-status-expired';
  } else if ([100, 102, 103, 104, 105, 300].includes(status)) {
    returnedClass = 'circle-shadow circle-status-failed';
  } else if (status === 4 || status === 107) {
    returnedClass = 'circle-shadow circle-status-ok';
  }
  return returnedClass;
};

// Class color for DELIVERY_SALE_STATUS
export const deliverySaleStatusClass = (status) => {
  let returnedClass = 'status-tag status-normal';
  if (status === 0 || status === 1 || status === 6) {
    returnedClass = 'status-tag status-expired';
  } else if (status === 5) {
    returnedClass = 'status-tag status-failed';
  } else if (status === 4) {
    returnedClass = 'status-tag status-ok';
  }
  return returnedClass;
};

// Class color for DELIVERY_SALE_SHOPIFY_STATUS
export const deliverySaleShopifyStatusClass = (status) => {
  let returnedClass = 'status-tag status-normal';
  if (status === 'fulfilled') {
    returnedClass = 'status-tag status-ok';
  } else {
    returnedClass = 'status-tag status-expired';
  }
  return returnedClass;
};

// Class color for DELIVERY_SALE_WOOCOMMERCE_STATUS
export const deliverySaleWoocommerceStatusClass = (status) => {
  let returnedClass = 'status-tag status-normal';
  if (status === 'completed') {
    returnedClass = 'status-tag status-ok';
  } else if (status === 'cancelled') {
    returnedClass = 'status-tag status-expired';
  }
  return returnedClass;
};

export const processingfilesStatusesClass = (status) => {
  switch (status) {
    case 0:
      return 'status-progress-text';
    case 1:
      return 'status-ok-text';
    case 2:
      return 'status-failed-text';
    case 3:
      return 'status-failed-text';
    default:
      return 'status-progress-text';
  }
};

export const piboxAdminRoles = (listRoles) => {
  return listRoles.filter(
    (rol) =>
      rol === 'super_admin' || rol === 'pibox_admin' || rol === 'pibox_ops'
  );
};

export const piboxAdminRolesCompany = (listRoles) => {
  return listRoles.filter((rol) => rol === 'company_admin');
};

export const piboxAccessDeniedRolesCompany = (listRoles) => {
  return listRoles.filter((rol) => rol === 'pibox_viewer' || rol === 'pibox_financial');
};

export const removeEmptyFilters = (params) => {
  Object.keys(params).forEach((key) => {
    if (params[key] === '' || params[key] == null) {
      delete params[key];
    }
  });
};
export const lastRelaunched = (relaunched) => {
  let flat = relaunched;
  let last = null;
  while (flat !== null) {
    last = flat;
    flat = flat.relaunched_to;
  }
  return last;
};

export const activeForPassenger = (booking) => {
  if (
    !booking ||
    (booking && (booking.status_cd === null || booking.status_cd === undefined))
  ) {
    return false;
  }
  return (
    booking.status_cd !== 100 &&
    booking.status_cd !== 102 &&
    booking.status_cd !== 101 &&
    booking.status_cd !== 104
  );
};

export const toIsoString = (date) => {
  var tzo = -date.getTimezoneOffset(),
    dif = tzo >= 0 ? '+' : '-',
    pad = function (num) {
      return (num < 10 ? '0' : '') + num;
    };

  return (
    date.getFullYear() +
    '-' +
    pad(date.getMonth() + 1) +
    '-' +
    pad(date.getDate()) +
    'T' +
    pad(date.getHours()) +
    ':' +
    pad(date.getMinutes()) +
    ':' +
    pad(date.getSeconds()) +
    dif +
    pad(Math.floor(Math.abs(tzo) / 60)) +
    ':' +
    pad(Math.abs(tzo) % 60)
  );
};

export const isObjectEmpty = (obj) => Object.keys(obj).length < 1;

export const removeDuplicatesIds = (arrayData) => {
  const ids = arrayData.map((data) => data._id);
  const filtered = arrayData.filter(
    ({ _id }, index) => !ids.includes(_id, index + 1)
  );
  return filtered ? filtered : arrayData;
};

export const integrationStatus = (type) => {
  switch (type) {
    case 'mercadoflex':
      return DELIVERY_SALE_STATUS;
    case 'shopify':
      return DELIVERY_SALE_SHOPIFY_STATUS_FILTERS;
    case 'woocommerce':
      return DELIVERY_SALE_WOOCOMMERCE_STATUS;
    case 'vtex':
      return SALES_VTEX_STATUS;
    default:
      return DELIVERY_SALE_STATUS;
  }
};

export const processingDocumentsStatusesClass = (status) => {
  switch (status) {
    case 0:
      return 'status-tag status-normal';
    case 1:
      return 'status-tag status-ok';
    case 3:
      return 'status-tag status-expired';
    default:
      return 'status-tag status-normal';
  }
};

export const promoCodeStatuses = (status) => {
  switch (status) {
    case 0:
      return 'status-tag-promo status-normal-promo-code';
    case 1:
      return 'status-tag-promo status-normal';
    case 2:
      return 'status-tag-promo status-ok';
    case 3:
      return 'status-tag-promo status-failed';
    case 4:
      return 'status-tag-promo status-failed';
    default:
      return 'status-tag-promo status-normal-promo-code';
  }
};

export const urlExports = (type, companyId = '') => {
  switch (type) {
    case 'bookings':
      return urlReportsBooking;
    case 'packages':
      return urlReportPackages;
    case 'promocodes':
      return `${urlCompany}/${companyId}/corporative_promo_codes/reports`;
    default:
      return '';
  }
};

export const getFileNameFromAWSUrlKey = (key) => {
  const filenameWithExtension = key.split('/').pop();
  return filenameWithExtension.split('.')[0];
};

export const replaceKeys = (obj, oldKeys, newKeys) => {
  const newObj = {};
  for (let key in obj) {
    const index = oldKeys.indexOf(key);
    if (index !== -1) {
      newObj[newKeys[index]] = obj[key];
    } else {
      newObj[key] = obj[key];
    }
  }
  return newObj;
};

export const findIndexByProperty = (arr, key, value) => {
  return arr.findIndex((obj) => obj[key] === value);
};

export const cleanObject = (obj) => {
  const newObj = {}
  Object.keys(obj).forEach((key) => {
    if (
      obj[key] !== '' &&
       obj[key] !== null &&
       obj[key] !== undefined &&
    JSON.stringify(obj[key]) !== JSON.stringify([null, null])) {
      newObj[key] = obj[key]
    }
  })
  return newObj
}
// Class color for status_cd
export const billPaymentStatusClass = (status) => {
  let returnedClass = 'status-tag-style status-normal-style';
  if (status === 2 || status === 4 || status === 5) {
    returnedClass = 'status-tag-style status-failed-style';
  } else if (status === 1) {
    returnedClass = 'status-tag-style status-ok-style';
  }
  return returnedClass;
}

export const prepackagesStatusClass = (status) => {
  let returnedClass = 'status-tag-style status-normal-style';
  if (status === 1 || status === 4 || status === 5) {
    returnedClass = 'status-tag-style status-failed-style';
  } else if (status === 3) {
    returnedClass = 'status-tag-style status-ok-style';
  }
  return returnedClass;
};

export const comodatoStatusClass = (status) => {
  let returnedClass = 'status-tag-style status-active-style';
  if (status === 7 || status === 8 || status === 9) {
    returnedClass = 'status-tag-style status-failed-style';
  } else if (status === 6) {
    returnedClass = 'status-tag-style status-ok-style';
  } else if (status === 2 || status === 3 || status === 4 || status === 5|| status === 10) {
    returnedClass = 'status-tag-style status-normal-style'
  }
  return returnedClass;
};

export const isAddonEnabled = (enabledAddons, addon) => enabledAddons?.includes(addon);

export const hasNoRoles = (company, roles) => company && piboxAccessDeniedRolesCompany(roles)?.length === 0;

export const formatDate = (date) => date ? format(new Date(date), 'dd-MM-yyyy') : null;
