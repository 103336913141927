import React, { useContext, useEffect, useState } from 'react';
import { Button, Grid, Typography, TextField, FormControl, InputLabel, Select, withStyles, createStyles } from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone';
import { I18nContext } from 'translations';
import { useStateValue } from 'context/store';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { getAproxAddress } from 'context/express/actions';
import ModalMap from 'components/Modals/ModalMap';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AutocompleteInput from 'components/CustomInputs/AutocompleteInput';
import { schema } from './schema';

const styles = createStyles(() => ({
  dropZone: {
    minHeight: '200px'
  }
}))

function FormOptimizeRoutes({
  file,
  city,
  submit,
  classes,
  origin,
  setCity,
  dispatch,
  setOrigin,
  handleFile,
  handleSuggest,
  costCenter
}) {
  const { translate } = useContext(I18nContext);
  const [open, setOpen] = useState(false);
  const [loadingAproxAddress, setLoadingAproxAddress] = useState(false);
  const [{ checkin: { configuration } }] = useStateValue();
  const { handleSubmit, register, errors } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const handleModalMap = () => setOpen(!open);

  const changeCoord = async (coord, type, index, id) => {
    setLoadingAproxAddress(true);
    const callback = await getAproxAddress(dispatch, coord);
    if (callback.aproxAddress) {
      setLoadingAproxAddress(false);
      setOrigin({ name: callback.aproxAddress, lat: coord.lat, lon: coord.lng })
    } else {
      setLoadingAproxAddress(false);
    }
  }

  useEffect(() => {
    configuration && configuration.cities_for_delivery && setCity(configuration.cities_for_delivery[0]);
  }, [configuration, setCity]);

  return (
    <form style={{ margin: '0 0 20px' }} onSubmit={handleSubmit(submit)} noValidate>
      <Grid container justify="center">
        <Grid item md={8}>
          <Typography variant="h5" color="textPrimary" className="text-center" style={{ margin: '15px 0 10px' }}>
            {translate('app.operations.optimize.loadExcelFile')} - 
            <Button
              color="secondary"
              href="https://s3.amazonaws.com/static.pibox.app/templates/template_routes_2023.xlsx"
              size="small"
              download
            >
              {translate('app.operations.optimize.downloadFormat')}
            </Button>
          </Typography>
          <DropzoneArea
            onChange={handleFile}
            acceptedFiles={['.xlsx', '.xls', '.csv']}
            maxFileSize={5000000}
            filesLimit={1}
            dropzoneClass={classes.dropZone}
            dropzoneText={translate('app.bulkLoad.dragFileHereOrClick')}
            getFileLimitExceedMessage={() => translate('app.bulkLoad.pleaseUploadOnlyOneFile')}
            getFileAddedMessage={fileName => `${fileName} ${translate('app.bulkLoad.successfullyAdded')}`}
            getFileRemovedMessage={fileName => `${fileName} ${'app.bulkLoad.removed'}`}
            getDropRejectMessage={fileName => translate('app.bulkLoad.unsupportedFileType')}
          />
        </Grid>

        {configuration && configuration.cities_for_delivery && city &&
          <Grid item md={11} style={{ margin: '15px 0 25px' }}>
            <Typography variant="subtitle1" style={{ marginBottom: 15 }}>
              {translate('app.operations.optimize.basicData')}
            </Typography>
            <Grid container spacing={3}>
              <Grid item md={6} sm={6} xs={12}>
                <Autocomplete
                  id="cities"
                  options={configuration.cities_for_delivery.length > 0 ? configuration.cities_for_delivery : [{ id: 'none', name: translate('app.express.noCities') }]}
                  autoHighlight
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => <TextField {...params} variant="outlined" size="small" />}
                  value={city ? city : configuration.cities_for_delivery[0]}
                  onChange={(event, newValue) => {
                    event.stopPropagation();
                    setCity(newValue);
                  }}
                />
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
                <AutocompleteInput
                  title={translate('app.operations.optimize.form.address')}
                  name="address"
                  address={origin && origin}
                  cityId={city._id}
                  pos={{ lat: city.location.lat, lon: city.location.lon }}
                  focus
                  handleChange={(info) => handleSuggest(info, 0, 0)}
                />
                <Button type="button" size="small" onClick={handleModalMap} color="primary" style={{ fontSize: '0.75rem', padding: '0px 5px' }}>
                  {translate('app.express.help')}
                </Button>
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
                <TextField
                  type="text"
                  name="number_of_vehicles"
                  variant="outlined"
                  label={translate('app.operations.optimize.form.numberOfVehicles')}
                  fullWidth
                  inputRef={register}
                  size="small"
                  defaultValue={1}
                  error={errors.number_of_vehicles && !!errors.number_of_vehicles}
                  helperText={errors.number_of_vehicles && errors.number_of_vehicles && translate(errors.number_of_vehicles.message)}
                />
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
                <TextField
                  type="text"
                  name="max_packages_per_driver"
                  variant="outlined"
                  label={translate('app.operations.optimize.form.maximumNumberOfPackagesPerDriver')}
                  fullWidth
                  inputRef={register}
                  size="small"
                  defaultValue={1}
                  error={errors.max_packages_per_driver && !!errors.max_packages_per_driver}
                  helperText={errors.max_packages_per_driver && errors.max_packages_per_driver && translate(errors.max_packages_per_driver.message)}
                />
              </Grid>
              {costCenter && costCenter.length > 0 && (
                <Grid item xs={6}>
                  <FormControl variant="outlined" fullWidth size="small">
                    <InputLabel>
                      {translate('app.bookings.costCenter')}
                    </InputLabel>
                    <Select
                      native
                      inputRef={register}
                      label={translate('app.bookings.costCenter')}
                      name="cost_center_id"
                      variant="outlined"
                      fullWidth
                      size="small"
                      defaultValue={costCenter[0]._id}
                    >
                      {costCenter.map(center => (
                        <option key={center._id} value={center._id}>
                          {center.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              <Grid item md={6} sm={6} xs={12}>
                <TextField
                  type="text"
                  name="max_capacity"
                  variant="outlined"
                  label={translate('app.operations.optimize.form.maxCapacity')}
                  fullWidth
                  inputRef={register}
                  size="small"
                  error={errors.max_capacity && !!errors.max_capacity}
                  helperText={errors.max_capacity && errors.max_capacity && translate(errors.max_capacity.message)}
                />
              </Grid>
            </Grid>
          </Grid>
        }
        <Grid container item sx={12} justify='center'>
        <Button
          type="submit"
          size="large"
          color="primary"
          variant="contained"
          disabled={!origin.lat || !file[0]}
          >
          {translate('app.operations.optimize.form.confirm')}
        </Button>
          </Grid>
      </Grid>
      {open && (
        <ModalMap
          open={open}
          handleModalMap={handleModalMap}
          city={{ lat: city.location.lat, lon: city.location.lon, name: city.name }}
          changeAddress={changeCoord}
          address={origin}
          type={0}
          loadingAproxAddress={loadingAproxAddress}
        />
      )}
    </form>
  )
}

export default withStyles(styles)(FormOptimizeRoutes);