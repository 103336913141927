import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  Typography,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  Grid,
  Slide,
  Snackbar,
  CircularProgress,
} from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { useForm, Controller } from 'react-hook-form';
import { useStateValue } from 'context/store';
import { message as closeMessage } from 'context/commons/actions';
import {
  getPseParams,
  newRechargePicash,
  getCreditCards,
  newRechargeWithCreditCard,
} from 'context/payments/actions';
import { modalMessage } from 'utils';
import { yupResolver } from '@hookform/resolvers';
import { I18nContext } from 'translations';
import schema from './schema';
import { COUNTRY_CODE, INITIAL_PAGE, PER_PAGE } from 'utils/constants';

function ModalRecharge({ refresh, open, setOpen, rechargeMethods }) {
  const { langCode, translate } = useContext(I18nContext);
  const [loading, setLoading] = useState(false);
  const [per_page] = useState(PER_PAGE);
  const [
    {
      payments: { pseParams, creditCards = [], creditCardsLoading, pseParamsLoading },
      commons: { message },
      checkin: { company },
    },
    dispatch,
  ] = useStateValue();
  const { handleSubmit, control, errors, register, watch, getValues } = useForm(
    {
      resolver: yupResolver(schema),
    }
  );
  const paymentMethodCode = watch('code');

  const messageOk = (redirectPage) => {
    setLoading(false);
    modalMessage(
      '',
      translate('app.payments.recharge.redirectedToThePsePage'),
      'warning',
      translate('accept'),
      true,
      true,
      translate('cancel')
    ).then(async (status) => {
      if (status.isConfirmed) {
        window.open(redirectPage, '_blank');
        setOpen(false);
      }
    });
  };

  const messageOkCreditCard = () => {
    setLoading(false);
    modalMessage(
      '',
      translate('app.payments.recharge.creditCardSuccess'),
      'success',
      translate('accept'),
      true
    ).then(() => {
      refresh();
      setOpen(false);
    });
  };

  const submit = async (values) => {
    setLoading(true);
    if (getValues('code') === 'co_pse') {
      const callback = await newRechargePicash(dispatch, values, langCode);
      callback.status ? messageOk(callback.redirectPage) : setLoading(false);
    } else if (getValues('code') === 'co_credit_card') {
      const callback = await newRechargeWithCreditCard(
        dispatch,
        values,
        langCode
      );
      callback.status ? messageOkCreditCard() : setLoading(false);
    }
  };

  const getCards = useCallback(
    async (page) => {
      await getCreditCards(dispatch, { page, per_page });
    },
    [dispatch, per_page]
  );

  useEffect(() => {
    paymentMethodCode === 'co_pse' && !pseParams && getPseParams(dispatch);
    paymentMethodCode === 'co_credit_card' && getCards(INITIAL_PAGE);
  }, [dispatch, paymentMethodCode, pseParams, getCards]);

  return (
    <Dialog
      open={open}
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle className="text-center" disableTypography>
        <Typography variant="h5" color="primary">
          {translate('app.payments.rechargePicash')}
        </Typography>
      </DialogTitle>

      <DialogContent>
        <form onSubmit={handleSubmit(submit)} noValidate>
          <div
            style={{
              maxHeight: 'calc(100vh - 240px)',
              overflowY: 'auto',
              padding: '7px 12px 0',
            }}
          >
            <FormControl required fullWidth style={{ marginBottom: '17px' }}>
              <Controller
                control={control}
                name="amount"
                defaultValue=""
                render={({ onChange, value }) => (
                  <NumberFormat
                    thousandSeparator
                    prefix="$"
                    customInput={TextField}
                    value={value}
                    onValueChange={(values) => {
                      const { value } = values;
                      onChange(value);
                    }}
                    placeholder={translate('app.payments.withdraw.value')}
                    allowNegative={false}
                    allowLeadingZeros={false}
                    size="small"
                    label={translate('app.payments.recharge.valueToRecharge')}
                    helperText={
                      value === ''
                        ? translate(
                            'app.payments.recharge.enterTheValueToRecharge'
                          )
                        : null
                    }
                    error={value === ''}
                    variant="outlined"
                  />
                )}
              />
            </FormControl>
            <FormControl
              fullWidth
              className="text-left"
              variant="outlined"
              size="small"
              style={{ marginBottom: '15px' }}
              required
            >
              <InputLabel>
                {translate('app.payments.recharge.paymentMethod')}
              </InputLabel>
              <Controller
                control={control}
                name="code"
                defaultValue=""
                label={translate('app.payments.recharge.paymentMethod')}
                as={
                  <Select>
                    {rechargeMethods.map((recharge) => {
                      return company.geo_fence_id === COUNTRY_CODE.es[0] ? (
                        ['co_pse', 'co_credit_card'].includes(
                          recharge.code
                        ) && (
                          <MenuItem value={recharge.code} key={recharge.code}>
                            {recharge.name}
                          </MenuItem>
                        )
                      ) : (
                        <MenuItem value={recharge.code} key={recharge.code}>
                          {recharge.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                }
              />
              {errors.code && (
                <p className="error">{translate(errors.code.message)}</p>
              )}
            </FormControl>
            {
              pseParamsLoading && 
              <div className="text-center">
                <CircularProgress color="primary" />
              </div>
            }
            {watch('code') === 'co_pse' &&
              (pseParams && (
                <Slide
                  direction="right"
                  in={true}
                  mountOnEnter
                  unmountOnExit
                  timeout={500}
                >
                  <div>
                    <Typography
                      variant="subtitle1"
                      style={{ marginBottom: '10px' }}
                    >
                      {translate('app.payments.recharge.pseData')}
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item md={12}>
                        <TextField
                          name="name"
                          defaultValue=""
                          label={translate('app.payments.recharge.name')}
                          variant="outlined"
                          size="small"
                          fullWidth
                          required
                          inputRef={register}
                          error={!!errors.name}
                          helperText={
                            errors.name && translate(errors.name.message)
                          }
                        />
                      </Grid>
                      <Grid item md={6}>
                        <FormControl
                          fullWidth
                          className="text-left"
                          variant="outlined"
                          size="small"
                        >
                          <InputLabel>
                            {translate('app.payments.recharge.documentType')}
                          </InputLabel>
                          <Controller
                            control={control}
                            name="id_type"
                            defaultValue=""
                            label={translate(
                              'app.payments.recharge.documentType'
                            )}
                            as={
                              <Select>
                                <MenuItem value="">
                                  <em>
                                    {translate('app.payments.withdraw.choose')}
                                  </em>
                                </MenuItem>
                                {pseParams &&
                                  pseParams.id_types.map((document) => (
                                    <MenuItem
                                      value={document.code}
                                      key={document.code}
                                    >
                                      {document.name}
                                    </MenuItem>
                                  ))}
                              </Select>
                            }
                          />
                          {errors.id_type && (
                            <p className="error">
                              {translate(errors.id_type.message)}
                            </p>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item md={6}>
                        <TextField
                          name="person_id"
                          defaultValue=""
                          label={translate(
                            'app.payments.recharge.documentNumber'
                          )}
                          variant="outlined"
                          size="small"
                          fullWidth
                          required
                          inputRef={register}
                          error={!!errors.person_id}
                          helperText={
                            errors.person_id &&
                            translate(errors.person_id.message)
                          }
                        />
                      </Grid>
                      <Grid item md={12}>
                        <FormControl
                          fullWidth
                          className="text-left"
                          variant="outlined"
                          size="small"
                        >
                          <InputLabel>
                            {translate('app.payments.recharge.kindOfPerson')}
                          </InputLabel>
                          <Controller
                            control={control}
                            name="person_type"
                            defaultValue=""
                            label={translate(
                              'app.payments.recharge.kindOfPerson'
                            )}
                            as={
                              <Select>
                                <MenuItem value="">
                                  <em>
                                    {translate('app.payments.withdraw.choose')}
                                  </em>
                                </MenuItem>
                                {pseParams &&
                                  pseParams.person_types.map((personTypes) => (
                                    <MenuItem
                                      value={personTypes.code}
                                      key={personTypes.code}
                                    >
                                      {personTypes.name}
                                    </MenuItem>
                                  ))}
                              </Select>
                            }
                          />
                          {errors.person_type && (
                            <p className="error">
                              {translate(errors.person_type.message)}
                            </p>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item md={6}>
                        <TextField
                          name="phone_number"
                          defaultValue=""
                          label={translate('app.payments.recharge.phone')}
                          variant="outlined"
                          size="small"
                          fullWidth
                          required
                          inputRef={register}
                          error={!!errors.phone_number}
                          helperText={
                            errors.phone_number &&
                            translate(errors.phone_number.message)
                          }
                        />
                      </Grid>
                      <Grid item md={6}>
                        <TextField
                          name="email"
                          defaultValue=""
                          label={translate('app.payments.recharge.email')}
                          variant="outlined"
                          size="small"
                          fullWidth
                          required
                          inputRef={register}
                          error={!!errors.email}
                          helperText={
                            errors.email && translate(errors.email.message)
                          }
                        />
                      </Grid>
                      <Grid item md={12}>
                        <TextField
                          name="address"
                          defaultValue=""
                          label={translate('app.payments.recharge.address')}
                          variant="outlined"
                          size="small"
                          fullWidth
                          required
                          inputRef={register}
                          error={!!errors.address}
                          helperText={
                            errors.address && translate(errors.address.message)
                          }
                        />
                      </Grid>
                      <Grid item md={12}>
                        <FormControl
                          fullWidth
                          className="text-left"
                          variant="outlined"
                          size="small"
                        >
                          <InputLabel>
                            {translate('app.payments.recharge.bank')}
                          </InputLabel>
                          <Controller
                            control={control}
                            name="bank_code"
                            defaultValue=""
                            label={translate('app.payments.recharge.bank')}
                            as={
                              <Select>
                                <MenuItem value="">
                                  <em>
                                    {translate('app.payments.withdraw.choose')}
                                  </em>
                                </MenuItem>
                                {pseParams &&
                                  pseParams.banks.map((bank) => (
                                    <MenuItem
                                      value={bank.bankcode}
                                      key={bank.bankcode}
                                    >
                                      {bank.bankname}
                                    </MenuItem>
                                  ))}
                              </Select>
                            }
                          />
                          {errors.bank_code && (
                            <p className="error">
                              {translate(errors.bank_code.message)}
                            </p>
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>
                  </div>
                </Slide>
                )
              )
            }
            {watch('code') === 'co_credit_card' &&
              (creditCardsLoading || !creditCards ? (
                <div className="text-center">
                  <CircularProgress color="primary" />
                </div>
              ) : (
                <Slide
                  direction="right"
                  in={true}
                  mountOnEnter
                  unmountOnExit
                  timeout={500}
                >
                  <div>
                    <Typography
                      variant="subtitle1"
                      style={{ marginBottom: '10px' }}
                    >
                      {translate('app.payments.recharge.creditCard')}
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item md={12}>
                        {creditCards?.cards?.length > 0 ? (
                          <FormControl
                            fullWidth
                            className="text-left"
                            variant="outlined"
                            size="small"
                          >
                            <InputLabel>
                              {translate('app.payments.recharge.creditCard')}
                            </InputLabel>
                            <Controller
                              control={control}
                              name="credit_card_id"
                              defaultValue=""
                              label={translate(
                                'app.payments.recharge.creditCard'
                              )}
                              as={
                                <Select>
                                  <MenuItem value="">
                                    <em>
                                      {translate(
                                        'app.payments.withdraw.choose'
                                      )}
                                    </em>
                                  </MenuItem>
                                  {creditCards.cards.map((card) => (
                                    <MenuItem
                                      value={card._id}
                                      key={card.number}
                                    >
                                      {card.number}
                                    </MenuItem>
                                  ))}
                                </Select>
                              }
                            />
                            {errors.credit_card_id && (
                              <p className="error">
                                {translate(errors.credit_card_id.message)}
                              </p>
                            )}
                          </FormControl>
                        ) : (
                          <Typography
                            variant="subtitle2"
                            style={{ marginBottom: '10px' }}
                          >
                            {translate('app.payments.recharge.creditCard.none')}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </div>
                </Slide>
              ))}
          </div>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading}
            >
              {translate('send')}
            </Button>
            <Button onClick={() => setOpen(false)} disabled={loading}>
              {translate('cancel')}
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
      <Snackbar
        style={{ zIndex: 99999 }}
        open={!!message}
        autoHideDuration={6000}
        onClose={() => closeMessage(dispatch, null)}
        message={translate(message)}
      />
    </Dialog>
  );
}

export default ModalRecharge;
