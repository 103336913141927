import React, { useEffect, useState, useCallback, useContext } from 'react';
import { Snackbar, Button } from '@material-ui/core';
import LocalAtmSharpIcon from '@material-ui/icons/LocalAtmSharp';
import { I18nContext } from 'translations';
import { useStateValue } from 'context/store';
import { piboxAccessDeniedRolesCompany } from 'utils';
import { message as closeMessage } from 'context/commons/actions';
import HeaderPayment from 'components/Headers/HeaderPayment';
import PicashTable from 'components/Tables/Payments/Picash';
import Loading from 'components/Loading';
import TitleWithDowload from 'components/Titles/TitleWithDowload';
import {
  getListPicash,
  downloadExcelPicash,
  getRechargeMethods,
} from 'context/payments/actions';
import { INITIAL_PAGE, PER_PAGE, COUNTRY_CODE } from 'utils/constants';
import PicashTableFilters from 'components/Tables/Payments/Filters/PicashTableFilters';
import ModalWithdraw from 'components/Modals/Payments/ModalWithdraw';
import ModalRecharge from 'components/Modals/Payments/ModalRecharge';

export default function Picash() {
  const { langCode, translate } = useContext(I18nContext);
  const [per_page] = useState(PER_PAGE);
  const [openRecharge, setOpenRecharge] = useState(false);
  const [filters, setFilters] = useState(null);
  const [openWithdraw, setOpenWithdraw] = useState(false);
  const [
    {
      payments: { listPicash, loadingPicash, rechargeMethods },
      commons: { message },
      checkin: { configuration, company, checkin },
    },
    dispatch,
  ] = useStateValue();

  const getRecharges = useCallback(async () => {
    const geo_fence_id = company
      ? company.geo_fence_id
      : COUNTRY_CODE[langCode][0];
    getRechargeMethods(dispatch, { geo_fence_id });
  }, [dispatch, company, langCode]);

  useEffect(() => {
    getRecharges();
  }, [getRecharges]);

  const getPicash = useCallback(
    async (page) => {
      configuration &&
        getListPicash(dispatch, { page, per_page, ...filters }, configuration);
    },
    [dispatch, per_page, filters, configuration]
  );

  useEffect(() => {
    getPicash(INITIAL_PAGE);
  }, [getPicash]);

  const onFilters = (params) => setFilters(params);

  const downloadExcel = async () =>
    await downloadExcelPicash(dispatch, configuration.account_id, {
      ...filters,
    });

  const role = () => {
    let rol = [];
    if (checkin && checkin.profile.roles) {
      rol = piboxAccessDeniedRolesCompany(checkin.profile.roles);
    }
    return rol;
  };
  const refresh = () => {
    getPicash();
    getRecharges();
  };

  return (
    <div style={{ background: '#fafafa' }}>
      {loadingPicash && <Loading />}
      <HeaderPayment
        account={listPicash && listPicash.account && listPicash.account}
      />

      <div className="text-center" style={{ margin: '15px 0' }}>
        <Button
          variant="outlined"
          color="primary"
          style={{ marginRight: 15, width: '170px' }}
          onClick={() => setOpenWithdraw(true)}
          disabled={!listPicash || (company && true) || role().length > 0 || (company && !company.active_company)}
        >
          {translate('app.payments.withdraw')}
        </Button>
        <Button
          variant="outlined"
          color="primary"
          style={{ width: '170px' }}
          onClick={() => setOpenRecharge(true)}
          disabled={!rechargeMethods || role().length > 0 || (company && !company.active_company)}
        >
          {translate('app.payments.rechargePicash')}
        </Button>
      </div>

      <div className="padding-page-full-height" style={{ background: 'white' }}>
        <TitleWithDowload
          title="Picash"
          button={translate('app.bookings.download')}
          icon={
            <LocalAtmSharpIcon
              className="align-center"
              style={{ marginRight: 7 }}
            />
          }
          downloadExcel={downloadExcel}
        />
        <div className="marginFiltersTable">
          <PicashTableFilters onFilters={onFilters} />
        </div>
        {listPicash && (
          <PicashTable
            data={listPicash}
            getPicash={getPicash}
            recordsPerPage={per_page}
          />
        )}
      </div>
      {openWithdraw && (
        <ModalWithdraw
          open={openWithdraw}
          setOpen={setOpenWithdraw}
          account={listPicash && listPicash.account}
        />
      )}
      {openRecharge && (
        <ModalRecharge
          refresh={refresh}
          open={openRecharge}
          setOpen={setOpenRecharge}
          rechargeMethods={rechargeMethods && rechargeMethods}
        />
      )}
      <Snackbar
        style={{ zIndex: 99999 }}
        open={!!message}
        autoHideDuration={6000}
        onClose={() => closeMessage(dispatch, null)}
        message={translate(message)}
      />
    </div>
  );
}
