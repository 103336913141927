import React, { lazy, Suspense } from 'react';
import { LinearProgress } from '@material-ui/core';
import withHelmet from 'utils/withHelmet';
const BannerWooCommerce = lazy(() =>
  import('components/Landing/Banners/BannerWooCommerce')
);
const UseWooCommerce = lazy(() =>
  import('components/Landing/WooCommerce/UseWooCommerce')
);
const AdvantagesWooCommerce = lazy(() =>
  import('components/Landing/WooCommerce/AdvantagesWooCommerce')
);
const LinkAccountsWooCommerce = lazy(() =>
  import('components/Landing/WooCommerce/LinkAccountsWooCommerce')
);
const LayoutLanding = lazy(() => import('components/Landing/Layouts'));

const Contact = lazy(() => import('components/Landing/Layouts/Contact'));

const LandingWooCommerce = () => (
  <Suspense fallback={<LinearProgress color="primary" />}>
    <LayoutLanding>
      <BannerWooCommerce />
      <UseWooCommerce />
      <AdvantagesWooCommerce />
      <LinkAccountsWooCommerce />
      <Contact />
    </LayoutLanding>
  </Suspense>
);

export default withHelmet({
  title: 'Shopify E-commerce | Pibox',
  description:
    'La alianza de Shopify con Pibox te permitirá llegar a todos tus clientes y sus productos mejorando tus tiempos de entrega',
  linkCanonical: 'https://pibox.app/shopify',
})(LandingWooCommerce);
